import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Styles
import "../../styles/authentication.css";

// Icons and Images
import EmailLogin from "../../assets/authenticationIconsAndImages/EmailLogin.png";

// Components
import { InputText } from "../../components/Inputs";

// Apis
import { resetPasswordUser, checkCurrentPassword, logoutUser } from "../../api/authentications";

// Helpers
import { timer } from "../../helpers/timer";
import { validatePassword } from "../../helpers/validatePassword"

// Cookies
import Cookies from "js-cookie";

const ChangePassword = (props) => {
  const {
    setterOpenFullscreen,
    authenticationState,
    CloseFullscreenBtn,
    isMobileSize
  } = props;

  const token = Cookies.get('ut')

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authenticationModal = useSelector((state) => state.authenticationModal);
  const userDetail = useSelector((state) => state.userDetail);
  
  const [notValidUser, setNotValidUser] = useState(false);
  
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [retypeNewPassword, setRetypeNewPassword] = useState("");

  const [invalidCurrentPassword, setInvalidCurrentPassword] = useState(false);
  const [invalidCurrentPasswordMessage, setInvalidCurrentPasswordMessage] = useState("");

  const [invalidNewPassword, setInvalidNewPassword] = useState(false);
  const [invalidNewPasswordMessage, setInvalidNewPasswordMessage] = useState("");

  const [invalidRetypeNewPassword, setInvalidRetypeNewPassword] = useState(false)
  const [invalidRetypeNewPasswordMessage, setInvalidRetypeNewPasswordMessage] = useState("");

  const handleValidation = async () => {
    let invalidNewPassword = false;
    let invalidNewPasswordMsg = ""

    let invalidRetypeNewPassword = false;
    let invalidRetypeNewPasswordMsg = ""

    if (!newPassword) {
      invalidNewPassword = true;
      invalidNewPasswordMsg =
        "This field is required";
    } else {
      const isValidPassword = validatePassword(newPassword);
      if (!isValidPassword) {
        invalidNewPassword = true;
        invalidNewPasswordMsg =
        "Password should be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one number.";
      }
    }

    if (!retypeNewPassword) {
      invalidRetypeNewPassword = true;
      invalidRetypeNewPasswordMsg = "This field is required";
    } else {
      if (retypeNewPassword !== newPassword) {
        invalidRetypeNewPassword = true;
        invalidRetypeNewPasswordMsg = "Password did not match"
      }
    }

    setInvalidNewPassword(invalidNewPassword)
    setInvalidNewPasswordMessage(invalidNewPasswordMsg)
    setInvalidRetypeNewPassword(invalidRetypeNewPassword)
    setInvalidRetypeNewPasswordMessage(invalidRetypeNewPasswordMsg)

    if (
      invalidNewPassword !== true &&
      invalidRetypeNewPassword !== true
    ) {
      setNotValidUser(false);
      return true;
    } else {
      return false;
    }
  };

  const handleCheckCurrentPassword = async () => {
    let invalidCurrentPassword = false;
    let invalidCurrentPasswordMsg = "";

    if (!currentPassword) {
      invalidCurrentPassword = true;
      invalidCurrentPasswordMsg = "This field is required"
    } else {
      const body = {
        email: userDetail?.email,
        password: currentPassword,
      }

      try {
        await checkCurrentPassword(body)
      } catch (err) {
        if (err && err.response && err.response.data) {
          invalidCurrentPassword = true;
          invalidCurrentPasswordMsg = "Wrong current password"
        }
      }
    }

    setInvalidCurrentPassword(invalidCurrentPassword)
    setInvalidCurrentPasswordMessage(invalidCurrentPasswordMsg)

    if (invalidCurrentPassword !== true) {
      return true
    } else {
      return false
    }
  }

  const handleOnClickBtnSubmit = async () => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    try {
      const isValidNewPassword = handleValidation();
      const isValidCurrentPassword = await handleCheckCurrentPassword()
      if (isValidNewPassword && isValidCurrentPassword) {
        const result = await resetPasswordUser({
          password: newPassword,
        });
        if (result && result.data) {
          setCurrentPassword("");
          setNewPassword("");
          setRetypeNewPassword("");
          const { data: { success } = {} } = result || {};
          if (success) {
            dispatch({
              type: "SET_AUTHENTICATION_MODAL",
              authenticationModal: {
                ...authenticationModal,
                changePassword: false,
              },
            });
            setterOpenFullscreen({
              ...authenticationState,
              changePassword: false,
            })
            // const beforeFunction = () => {
            //   return dispatch({
            //     type: "SET_IS_SUCCESS_REQUEST_RESET_PASSWORD",
            //     isSuccessRequestResetPassword: true,
            //   });
            // };
            // const afterFunction = () => {
            //   return dispatch({
            //     type: "SET_IS_SUCCESS_REQUEST_RESET_PASSWORD",
            //     isSuccessRequestResetPassword: false,
            //   });
            // };
            // timer(beforeFunction, afterFunction, 3000);

            const response = await logoutUser();
            if (
              response &&
              response.data &&
              response.data.message &&
              response.data.message === "User logged out"
            ) {
              Cookies.remove("ut");
              dispatch({ type: "SET_USER_DETAIL", userDetail: null });
              dispatch({ type: "SET_USERS_CART", shoppingCarts: [] });
              dispatch({ type: "SET_TOTAL_USERS_CART", totalUsersCart: null });
              const beforeFunction = () => {
                return dispatch({
                  type: "SET_NOTIFICATION",
                  notification: {
                    success: true,
                    msg: "Successfully logged out",
                    show: true,
                  },
                });
              };
              const afterFunction = () => {
                return dispatch({
                  type: "SET_NOTIFICATION",
                  notification: {
                    success: false,
                    msg: "",
                    show: false,
                  },
                });
              };
              timer(beforeFunction, afterFunction, 3000);
              navigate("/");
            }
          }
        }
      }
    } catch (err) {
      if (err && err.response && err.response.data) {
        const { response: { data: { message } = {} } = {} } = err || {};
        if (message === "user-not-found") {
          setNotValidUser(true);
        }
      }
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  return (
    <div className="changePasswordBoxMainContainer">
      <div
        className="closeBtn"
        style={{ width: "100%", marginRight: isMobileSize ? "2em" : "4em" }}
        onClick={() =>
          setterOpenFullscreen({
            ...authenticationState,
            changePassword: false,
          })
        }
      >
        <imginvalidEmail
          src={CloseFullscreenBtn}
          alt="close-fullscreen-btn"
          width={isMobileSize ? 12 : 28}
          height={isMobileSize ? 12 : 28}
        />
      </div>
      <div className="tabNameStyle">Update your password</div>
      <div className="descForgotPasswordStyle">
        Please enter your current password and a new password
      </div>
      <div
        className="loginInputContainer"
        style={{ marginTop: "2em", marginLeft: "0.1vw" }}
      >
        <div>
          <div className="inputLabelWithIcon">
            <img src={EmailLogin} alt="email-icon" />
            <div className="inputLabel">Current Password</div>
          </div>
          <InputText
            width={isMobileSize ? "100%" : "100%"}
            fontSize={isMobileSize ? "14px" : "16px"}
            border={"1px solid #000000"}
            borderRadius={"10px"}
            height={"5vh"}
            placeholder={"Fill in current password"}
            name={"currentPassword"}
            value={currentPassword}
            setter={setCurrentPassword}
          />
          {invalidCurrentPassword || notValidUser ? (
            <div className="errorMessage">
              {invalidCurrentPassword
                ? `${invalidCurrentPasswordMessage}`
                : notValidUser
                ? "User's not valid"
                : "Please input a valid email format"}
            </div>
          ) : null}
        </div>
      </div>
      <div
        className="loginInputContainer"
        style={{ marginTop: "1em", marginLeft: "0.1vw" }}
      >
        <div>
          <div className="inputLabelWithIcon">
            <img src={EmailLogin} alt="email-icon" />
            <div className="inputLabel">Type your new password</div>
          </div>
          <InputText
            width={isMobileSize ? "100%" : "100%"}
            fontSize={isMobileSize ? "14px" : "16px"}
            border={"1px solid #000000"}
            borderRadius={"10px"}
            height={"5vh"}
            placeholder={"Fill in new password"}
            name={"newPassword"}
            value={newPassword}
            setter={setNewPassword}
          />
          {invalidNewPassword || notValidUser ? (
            <div className="errorMessage">
              {invalidNewPassword
                ? `${invalidNewPasswordMessage}`
                : notValidUser
                ? "User's not valid"
                : "Please input a valid email format"}
            </div>
          ) : null}
        </div>
      </div>
      <div
        className="loginInputContainer"
        style={{ marginTop: "1em", marginLeft: "0.1vw" }}
      >
        <div>
          <div className="inputLabelWithIcon">
            <img src={EmailLogin} alt="email-icon" />
            <div className="inputLabel">Retype your new password</div>
          </div>
          <InputText
            width={isMobileSize ? "100%" : "100%"}
            fontSize={isMobileSize ? "14px" : "16px"}
            border={"1px solid #000000"}
            borderRadius={"10px"}
            height={"5vh"}
            placeholder={"Fill in retype new password"}
            name={"retypeNewPassword"}
            value={retypeNewPassword}
            setter={setRetypeNewPassword}
          />
          {invalidRetypeNewPassword || notValidUser ? (
            <div className="errorMessage">
              {invalidRetypeNewPassword
                ? `${invalidRetypeNewPasswordMessage}`
                : notValidUser
                ? "User's not valid"
                : "Please input a valid email format"}
            </div>
          ) : null}
        </div>
      </div>
      <div
        className="btnSignInContainer"
        style={{
          background: !newPassword ? "#C4C4C4" : "#006787",
          marginTop: isMobileSize ? "1.5em" : "2em",
        }}
        onClick={() => handleOnClickBtnSubmit()}
      >
        <div>Submit</div>
      </div>
    </div>
  );
};

export default ChangePassword;
