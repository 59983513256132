import React from "react";
import { useNavigate } from "react-router-dom";

// Images and Icons
import GreyArrowLeftIcon from "../../../assets/itemImagesAndIcons/GreyArrowLeftIcon.png";
import GreyArrowRightIcon from "../../../assets/itemImagesAndIcons/GreyArrowRightIcon.png";
import ArrowLeftIcon from "../../../assets/itemImagesAndIcons/ArrowLeftIcon.png";
import ArrowRightIcon from "../../../assets/itemImagesAndIcons/ArrowRightIcon.png";
import ArrowDownIcon from "../../../assets/navbarIcons/ArrowDownIcon.png";
import ArrowUpIcon from "../../../assets/navbarIcons/ArrowUpIcon.png";
import SuccessNotificationIcon from "../../../assets/notificationIcons/SuccessNotificationIcon.png"

// Styles
import "../../../styles/items.css";

// Components
import Pagination from "../../../components/Pagination";

// Images and Icons
import EmptyWishlistIcon from "../../../assets/myProfileIcons/EmptyWishlistIcon.png";

const ItemList = (props) => {
  const {
    products,
    searchState,
    productListPageState: { currentPage, totalPages } = {},
    handleOnChangePage,
    isMobileSize,
    showFilterOptions,
    sidebarFilterVal,
    handleOnClickArrowIcon,
    handleOnClickInputSidebarFilter,
    formatPrice,
    skeletonLoader,
  } = props;
  const navigate = useNavigate();

  if (skeletonLoader) {
    return (
      <div className="itemContentContainer">
        {isMobileSize ? (
          <>
            <div className="space-y-1">
              <div className="loading-btn bg-slate-300 w-full aspect-square rounded-xl" />
              <div className="loading-btn bg-slate-300 w-16 h-3 rounded-xl" />
              <div className="loading-btn bg-slate-300 w-32 h-3 rounded-xl" />
              <div className="loading-btn bg-slate-300 w-24 h-3 rounded-xl" />
            </div>
            <div className="space-y-1">
              <div className="loading-btn bg-slate-300 w-full aspect-square rounded-xl" />
              <div className="loading-btn bg-slate-300 w-16 h-3 rounded-xl" />
              <div className="loading-btn bg-slate-300 w-32 h-3 rounded-xl" />
              <div className="loading-btn bg-slate-300 w-24 h-3 rounded-xl" />
            </div>
            <div className="space-y-1">
              <div className="loading-btn bg-slate-300 w-full aspect-square rounded-xl" />
              <div className="loading-btn bg-slate-300 w-16 h-3 rounded-xl" />
              <div className="loading-btn bg-slate-300 w-32 h-3 rounded-xl" />
              <div className="loading-btn bg-slate-300 w-24 h-3 rounded-xl" />
            </div>
            <div className="space-y-1">
              <div className="loading-btn bg-slate-300 w-full aspect-square rounded-xl" />
              <div className="loading-btn bg-slate-300 w-16 h-3 rounded-xl" />
              <div className="loading-btn bg-slate-300 w-32 h-3 rounded-xl" />
              <div className="loading-btn bg-slate-300 w-24 h-3 rounded-xl" />
            </div>
            <div className="space-y-1">
              <div className="loading-btn bg-slate-300 w-full aspect-square rounded-xl" />
              <div className="loading-btn bg-slate-300 w-16 h-3 rounded-xl" />
              <div className="loading-btn bg-slate-300 w-32 h-3 rounded-xl" />
              <div className="loading-btn bg-slate-300 w-24 h-3 rounded-xl" />
            </div>
            <div className="space-y-1">
              <div className="loading-btn bg-slate-300 w-full aspect-square rounded-xl" />
              <div className="loading-btn bg-slate-300 w-16 h-3 rounded-xl" />
              <div className="loading-btn bg-slate-300 w-32 h-3 rounded-xl" />
              <div className="loading-btn bg-slate-300 w-24 h-3 rounded-xl" />
            </div>
          </>
        ) : (
          <div style={{ display: "flex", gap: "4vw" }}>
            <div className="gallery-item">
              <div className="image-background">
                <div className="loading-btn image-background-image bg-slate-300 rounded-3xl"></div>
              </div>
              <div className="eachItemDesc">
                <div className="loading-btn w-48 md:w-40 lg:w-60 h-4 md:h-3 lg:h-5 bg-slate-300 rounded-xl"></div>
                <div className="pt-1 pb-1"></div>
                <div className="loading-btn w-48 md:w-40 lg:w-60 h-4 md:h-3 lg:h-5 bg-slate-300 rounded-xl"></div>
              </div>
            </div>
            <div className="gallery-item">
              <div className="image-background">
                <div className="loading-btn image-background-image bg-slate-300 rounded-3xl"></div>
              </div>
              <div className="eachItemDesc">
                <div className="loading-btn w-48 md:w-40 lg:w-60 h-4 md:h-3 lg:h-5 bg-slate-300 rounded-xl"></div>
                <div className="pt-1 pb-1"></div>
                <div className="loading-btn w-48 md:w-40 lg:w-60 h-4 md:h-3 lg:h-5 bg-slate-300 rounded-xl"></div>
              </div>
            </div>
            <div className="gallery-item">
              <div className="image-background">
                <div className="loading-btn image-background-image bg-slate-300 rounded-3xl"></div>
              </div>
              <div className="eachItemDesc">
                <div className="loading-btn w-48 md:w-40 lg:w-60 h-4 md:h-3 lg:h-5 bg-slate-300 rounded-xl"></div>
                <div className="pt-1 pb-1"></div>
                <div className="loading-btn w-48 md:w-40 lg:w-60 h-4 md:h-3 lg:h-5 bg-slate-300 rounded-xl"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="mainContentContainer">
        {(!isMobileSize || (isMobileSize && showFilterOptions)) && (
          <div className="sortByDropdownMainContainer">
            <div className="sortByDropdownContainer">
              {sidebarFilterVal &&
                Array.isArray(sidebarFilterVal) &&
                sidebarFilterVal.length > 0 &&
                sidebarFilterVal.map((sidebarFilter, index) => {
                  const { optionName, optionValue, isOpenOption } =
                    sidebarFilter;
                  if (
                    optionValue &&
                    Array.isArray(optionValue) &&
                    optionValue.length > 0
                  ) {
                    if (optionName === "Sort by") {
                      let selectedOptionName = optionValue.filter((option) => option.isSelected === true)[0]?.label || optionName;
                      return (
                        <div
                          key={index}
                          style={{
                            border: "0.5px solid grey",
                            width: isMobileSize ? "92vw" : "15vw",
                            background: "#ffffff",
                            cursor: "pointer",
                            borderRadius: "10px",
                            boxShadow: "4px 15px 57px -21px rgba(0,0,0,0.5)"
                          }}
                          onClick={() => handleOnClickArrowIcon(optionName)}
                        >
                          <div className="leftFilterBoxEachOption">
                            <div className="leftFilterBoxOptionTitle">
                              {selectedOptionName}
                            </div>
                            <div className="leftFilterBoxUnselectAndArrowIconContainer">
                              <img
                                src={isOpenOption ? ArrowDownIcon : ArrowUpIcon}
                                alt={
                                  isOpenOption
                                    ? "arrow-down-icon"
                                    : "arrow-up-icon"
                                }
                                className="leftFilterDropdownIcon"
                              ></img>
                            </div>
                          </div>
                          {
                            isOpenOption && <hr style={{borderTop: "2px solid #bbb"}} />
                          }
                          {isOpenOption ? (
                            <div
                              className="leftFilterCheckboxContainer"
                              style={{ marginLeft: "0.7vw" }}
                            >
                              {optionValue.map((optionVal, idx) => {
                                const { label, isSelected } = optionVal;
                                return (
                                  <div
                                    className="divInputCheckbox mt-3"
                                    key={idx}
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleOnClickInputSidebarFilter(
                                        optionName,
                                        optionVal,
                                        true
                                      )
                                    }
                                  >
                                    {label &&
                                      (label.minPrice ||
                                        label.minPrice === 0 ||
                                        label.minPrice === "0") &&
                                      label.maxPrice ? (
                                      <div>{`${label.minPrice.toLocaleString(
                                        "id-ID"
                                      )} - ${label.maxPrice.toLocaleString(
                                        "id-ID"
                                      )}`}</div>
                                    ) : (
                                      <div className="flex space-x-1 items-center">
                                        <input type="radio" checked={isSelected} name={label}/>
                                        <label className="text-left" style={{ fontWeight: isSelected ? "700" : "400" }}>{label}</label>
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          ) : null}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  } else {
                    return null;
                  }
                })}
            </div>
          </div>
        )}
        <div className="itemContentContainer">
          {products && Array.isArray(products) && products.length > 0 ? (
            products.map((item, index) => {
              const {
                id,
                name,
                price,
                images,
                product_discounts,
                discounted_price,
                brand
              } = item || {};
              let img_url;
              if (images && Array.isArray(images) && images.length > 0) {
                img_url = images[0] && images[0].image_url;
              }
              const productDiscount =
                product_discounts &&
                  Array.isArray(product_discounts) &&
                  product_discounts.length > 0
                  ? product_discounts[0]
                  : null;
              const salePrice = discounted_price;

              return (
                <div
                  key={id}
                  className="eachItemContentContainer"
                  data-aos={isMobileSize ? "fade-down" : "fade-right"}
                  data-aos-duration="3000"
                  data-aos-once
                  onClick={() => navigate(`/items/${id}`)}
                >
                  <div className="eachItemContentImgContainer">
                    <img src={img_url} alt={`Each-item-${index + 1}`} className="border-2 border-maplexxon-grey border-solid"/>
                  </div>
                  <div className="eachItemDesc">
                    <div className="text-xs font-bold text-[#006787] mb-1">{brand?.name}</div>
                    <div className="font-semibold capitalize">{name}</div>
                    <div className="itemPriceContainer">
                      <div>
                        {`${(searchState &&
                          searchState.currencyVal &&
                          searchState.currencyVal.code) ||
                          ""
                          }`}
                      </div>
                      <div className={(productDiscount?.value_discount || productDiscount?.percentage_discount) ? "line-through" : ""}>
                        {formatPrice(price)}
                      </div>
                      {/* {productDiscount &&
                      productDiscount.percentage_discount &&
                      productDiscount.percentage_discount !== 0 ? (
                        <div
                          style={{ marginLeft: "0.75vw" }}
                        >{`${productDiscount.percentage_discount}% OFF`}</div>
                      ) : null} */}
                    </div>
                    {salePrice ? (
                      <div className="salePriceStyle">
                        {" "}
                        {`${(searchState &&
                          searchState.currencyVal &&
                          searchState.currencyVal.code) ||
                          ""
                          } ${formatPrice(salePrice)}`}
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="emptyProductContainer">
              <img src={EmptyWishlistIcon} alt="empty-wishlist-icon" />
              <div>No results.</div>
            </div>
          )}
        </div>
        {totalPages &&
          totalPages > 1 &&
          products &&
          Array.isArray(products) &&
          products.length > 0 ? (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            GreyArrowLeftIcon={GreyArrowLeftIcon}
            GreyArrowRightIcon={GreyArrowRightIcon}
            ArrowLeftIcon={ArrowLeftIcon}
            ArrowRightIcon={ArrowRightIcon}
            onPageChange={(page) => handleOnChangePage(page)}
          />
        ) : null}
      </div>
    );
  }
};

export default ItemList;
