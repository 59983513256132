import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// Images and Icons
import EditIProfilecon from "../../../assets/myProfileIcons/EditIProfilecon.png";
import ArrowUpIcon from "../../../assets/navbarIcons/ArrowUpIcon.png"

// Components
import {
  InputDropdown,
  InputDropdownAsync,
  InputText,
  PhoneNumberInput,
} from "../../../components/Inputs";
import FullscreenView from "../../../components/FullscreenView";

// Styles
import "../../../styles/shoppingCart.css";

// Core UI Icons
import * as icon from "@coreui/icons";
import CIcon from "@coreui/icons-react";

// Apis
import {
  createUserProfile,
  updateUserProfile,
  getUserProfile
} from "../../../api/authentications";
import { getAllCountries } from "../../../api/countries";

// Helpers
import { createCountriesOptions } from "../../../helpers/getCountries";
import { timer } from "../../../helpers/timer";

// Cookies
import Cookies from "js-cookie";

const MyProfileTab = ({ dispatch, userDetail, isMobileSize }) => {
  const navigate = useNavigate();
  const [isEditable, setIsEditable] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [genderProfile, setGenderProfile] = useState(null);
  const [emailAddress, setEmailAddress] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState({
    number: "",
    icon: icon.cifId,
    phone_code: "+62",
    country_id: 104,
  });

  const [countryOptions, setCountryOptions] = useState([]);
  const [isUserVerified, setIsUserVerified] = useState(false);
  const [isOpenMyProfilePhoneDropdown, setIsOpenMyProfilePhoneDropdown] =
    useState(false);

  const [isShowChangePasswordPopup, setIsShowChangePasswordPopup] = useState(false);

  useEffect(() => {
    if (userDetail) {
      handleFetchMyProfileForm(userDetail);
    }
  }, [userDetail]);

  const authenticationModal = useSelector((state) => state.authenticationModal);

  const handleFetchMyProfileForm = (userDetail) => {
    const { email, profile, verification, user_uuid } = userDetail || {};
    if (profile && profile.first_name) {
      setFirstName(profile.first_name);
    }
    if (profile && profile.last_name) {
      setLastName(profile.last_name);
    }
    if (profile && profile.gender) {
      setGenderProfile({ label: profile.gender, value: profile.gender });
    }
    if (email) {
      setEmailAddress(email);
    }
    if (verification && verification.is_verified) {
      setIsUserVerified(verification.is_verified);
    }
    if (user_uuid) {
      setCustomerId(user_uuid);
    }
    let countryIcon;
    let number;
    let phone_code;
    let country_id;
    if (profile && profile.country && profile.country.country_code) {
      countryIcon = icon[`cif${profile.country.country_code}`];
    }
    if (profile && profile.phone_number) {
      number = profile.phone_number;
    }
    if (profile && profile.country && profile.country.phone_code) {
      phone_code = profile.country.phone_code;
    }
    if (profile && profile.country && profile.country.id) {
      country_id = profile.country.id;
    }
    setPhoneNumber({
      ...phoneNumber,
      icon: countryIcon,
      phone_code,
      number,
      country_id,
    });
    fetchAllCountries();
  };

  const fetchAllCountries = async () => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    const response = await getAllCountries();
    if (response && response.data) {
      const { data } = response;
      if (data && Array.isArray(data) && data.length > 0) {
        setCountryOptions(createCountriesOptions(data, isMobileSize));
      }
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  const handleOnChangePhoneNumber = (
    value,
    phoneNumberState,
    setterPhoneNumber
  ) => {
    let parts = value.split(" ");
    let withoutCountryCode;

    if (phoneNumberState && phoneNumberState.phone_code) {
      withoutCountryCode = parts[1];
    } else {
      withoutCountryCode = parts.join(" ");
    }

    setterPhoneNumber({
      ...phoneNumberState,
      number: withoutCountryCode,
    });
  };


  const handleChangePhoneNumberDropdown = (
    val,
    phoneNumberState,
    setterPhoneNumber
  ) => {
    const { value: { number, country_code, phone_code, id } = {} } = val || {};
    let countryIcon = "";
    if (country_code) {
      countryIcon = icon[`cif${country_code}`];
    }
    setterPhoneNumber({
      ...phoneNumberState,
      number,
      icon: countryIcon,
      phone_code,
      country_id: id,
    });
    setIsOpenMyProfilePhoneDropdown(false);
  };

  const handleOnClickBtnSave = async () => {
    let body = {};
    if (emailAddress) {
      body.email = emailAddress;
    }
    if (firstName) {
      body.first_name = firstName;
    }
    if (lastName) {
      body.last_name = lastName;
    }
    if (genderProfile && genderProfile.value) {
      body.gender = genderProfile.value;
    }
    if (phoneNumber) {
      if (phoneNumber.country_id) {
        body.country_id = phoneNumber.country_id;
      }
      if (phoneNumber.number) {
        body.phone_number = phoneNumber.number;
      }
    }
    if (userDetail && userDetail.profile) {
      dispatch({
        type: "SET_IS_LOADING",
        isLoading: true,
      });
      const updatedUser = await updateUserProfile(body);
      if (updatedUser && updatedUser.data && updatedUser.data.id) {
        const result = await getUserProfile();
        if (result && result.data) {
          dispatch({ type: "SET_USER_DETAIL", userDetail: result.data });
        }
        // Cookies.remove("ut");
        // dispatch({ type: "SET_USERS_CART", shoppingCarts: [] });
        // dispatch({ type: "SET_TOTAL_USERS_CART", totalUsersCart: null });
        const beforeFunction = () => {
          return dispatch({
            type: "SET_NOTIFICATION",
            notification: {
              success: true,
              msg: "Update profile success",
              show: true,
            },
          });
        };
        const afterFunction = () => {
          return dispatch({
            type: "SET_NOTIFICATION",
            notification: {
              success: false,
              msg: "",
              show: false,
            },
          });
        };
        timer(beforeFunction, afterFunction, 3000);
        setIsEditable(false);
      }
      dispatch({
        type: "SET_IS_LOADING",
        isLoading: false,
      });
    } else {
      dispatch({
        type: "SET_IS_LOADING",
        isLoading: true,
      });
      const createdUserProfile = await createUserProfile(body);
      if (
        createdUserProfile &&
        createdUserProfile.data &&
        createdUserProfile.data.id
      ) {
        Cookies.remove("ut");
        dispatch({ type: "SET_USER_DETAIL", userDetail: null });
        dispatch({ type: "SET_USERS_CART", shoppingCarts: [] });
        dispatch({ type: "SET_TOTAL_USERS_CART", totalUsersCart: null });
        const beforeFunction = () => {
          return dispatch({
            type: "SET_NOTIFICATION",
            notification: {
              success: true,
              msg: "Update profile success, please re-login",
              show: true,
            },
          });
        };
        const afterFunction = () => {
          return dispatch({
            type: "SET_NOTIFICATION",
            notification: {
              success: false,
              msg: "",
              show: false,
            },
          });
        };
        timer(beforeFunction, afterFunction, 3000);
        setIsEditable(false);
      }
      dispatch({
        type: "SET_IS_LOADING",
        isLoading: false,
      });
    }
  };

  const handleOnChangeGenderDropdown = (val, genderState, genderSetter) => {
    genderSetter(val);
  };

  const populateDropdownCountryValue = (country_id) => {
    let value = countryOptions.filter((opt) => {
      if (opt.selectedKey === country_id) {
        return opt;
      }
    });
    return value[0];
  };

  const handleChangePasswordModal = () => {
    setIsShowChangePasswordPopup(true)
    dispatch({
      type: "SET_AUTHENTICATION_MODAL",
      authenticationModal: {
        ...authenticationModal,
        changePassword: true,
      },
    })
  }

  return (
    <div className="tabMainContainer">
      <div className="tabNameContainer">
        {!isMobileSize ? <div className="tabNameStyle">My Profile</div> : null}
        <div
          className="verifiedUserContainer"
          style={{
            background: "#c8faf1",
          }}
        >
          <div>{isUserVerified ? "VERIFIED" : "NOT VERIFIED"}</div>
        </div>
      </div>
      <div
        className="inputContainer"
        style={isMobileSize ? { marginTop: "0" } : {}}
      >
        <div style={{ width: isMobileSize ? "100%" : "42.2%" }}>
          <div
            className="inputLabel"
            style={{
              fontSize: isMobileSize ? "12px" : ""
            }}
          >
            First name
          </div>
          <InputText
            placeholder={"Fill in your first name"}
            width={"100%"}
            height={isMobileSize ? "37.5px" : "45px"}
            fontSize={isMobileSize ? "12px" : "16px"}
            value={firstName}
            setter={setFirstName}
            name={"myProfileTabFirstName"}
            border={"1px solid #000000"}
            borderRadius={"5px"}
            disabled={!isEditable}
          />
        </div>
        <div
          style={{
            width: isMobileSize ? "100%" : "42.2%",
            paddingTop: isMobileSize ? "1em" : "0",
          }}
        >
          <div
            className="inputLabel"
            style={{
              fontSize: isMobileSize ? "12px" : ""
            }}
          >
            Last name
          </div>
          <InputText
            placeholder={"Fill in your last name"}
            width={"100%"}
            height={isMobileSize ? "37.5px" : "45px"}
            fontSize={isMobileSize ? "12px" : "16px"}
            value={lastName}
            setter={setLastName}
            name={"myProfileTabLastName"}
            border={"1px solid #000000"}
            borderRadius={"5px"}
            disabled={!isEditable}
          />
        </div>
      </div>
      <div
        className="inputContainer"
        style={isMobileSize ? { marginTop: "1em" } : {}}
      >
        <div style={{ width: isMobileSize ? "100%" : "42.2%" }}>
          <div
            className="inputLabel"
            style={{
              paddingBottom: "1vh",
              fontSize: isMobileSize ? "12px" : "",
            }}>
            Gender
          </div>
          <InputDropdown
            options={[
              { label: "Male", value: "Male" },
              { label: "Female", value: "Female" },
            ]}
            styles={{
              marginTop: "-0.75em",
              border: "1px solid #000000",
              borderRadius: "5px",
              height: isMobileSize ? "37.5px" : "45px",
              fontSize: isMobileSize ? "12px" : "16px",
              width: "100%",
              cursor: "pointer",
            }}
            value={genderProfile}
            setterPhoneNumber={setGenderProfile}
            functionSetter={handleOnChangeGenderDropdown}
            disabled={!isEditable}
          />
        </div>
        <div
          style={{
            width: isMobileSize ? "100%" : "42.2%",
            marginTop: isMobileSize ? "1em" : "",
          }}
        >
          <div
            className="inputLabel"
            style={{
              fontSize: isMobileSize ? "12px" : ""
            }}
          >
            Email address
          </div>
          <InputText
            placeholder={"Fill in your email"}
            width={"100%"}
            height={isMobileSize ? "37.5px" : "45px"}
            fontSize={isMobileSize ? "12px" : "16px"}
            name={"myProfileTabEmailAddress"}
            border={"1px solid #000000"}
            value={emailAddress}
            setter={setEmailAddress}
            borderRadius={"5px"}
            disabled={true}
          />
        </div>
      </div>
      <div
        className="inputContainer"
        style={isMobileSize ? { marginTop: "0" } : {}}
      >
        <div
          style={{
            width: isMobileSize ? "100%" : "42.2%",
            paddingTop: isMobileSize ? "1em" : "0",
          }}
        >

          <div
            className="inputLabel"
            style={{
              fontSize: isMobileSize ? "12px" : ""
            }}
          >
            Customer ID
          </div>
          <InputText
            placeholder={"Fill in your customer ID"}
            width={"100%"}
            height={isMobileSize ? "37.5px" : "45px"}
            fontSize={isMobileSize ? "12px" : "16px"}
            value={customerId}
            setter={setCustomerId}
            name={"myProfileTabCustomerId"}
            border={"1px solid #000000"}
            borderRadius={"5px"}
            disabled={true}
          />
        </div>
        <div
          style={{
            width: isMobileSize ? "100%" : "42.25%",
            paddingTop: isMobileSize ? "1em" : "0",
          }}
        >
          <div className="inputLabel">Phone number</div>
          <PhoneNumberInput
            selectedCountry={phoneNumber}
            onChangeCountry={(value) => handleChangePhoneNumberDropdown(value, phoneNumber, setPhoneNumber)}
            phoneNumberValue={phoneNumber?.number}
            onChangePhoneNumber={(value) => setPhoneNumber(prev => ({ ...prev, number: value }))}
            countryOptions={countryOptions}
            isMobileSize={isMobileSize}
            disabled={!isEditable}
          />
        </div>
      </div>
      {
        isEditable && (
          <div className="changePassword">
            <p
              onClick={() =>
                handleChangePasswordModal()
              }
            >
              Change password?
            </p>
          </div>
        )
      }
      {
        isShowChangePasswordPopup &&
        authenticationModal && (
          <FullscreenView 
            flagger={"authentication-form"}
            setterOpenFullscreen={(objValue) => {
              dispatch({
                type: "SET_AUTHENTICATION_MODAL",
                authenticationModal: objValue,
              })
              if (!objValue.changePassword) {
                setIsShowChangePasswordPopup(false);
              }
            }}
            authenticationState={authenticationModal}
            navigate={navigate}
          />
        )
      }
      <div
        className="inputContainer"
        style={isMobileSize ? { marginTop: "1em" } : {}}
      ></div>
      {isEditable ? (
        <div className="cancelAndSaveBtnContainer">
          <div className="cancelAndSaveBtnContentContainer">
            <div
              className="btnCancelEditProfile"
              onClick={() => {
                setIsOpenMyProfilePhoneDropdown(false);
                setIsEditable(false);
              }}
            >
              <div>Cancel</div>
            </div>
            <div
              className="btnSaveEditProfile"
              onClick={() => handleOnClickBtnSave()}
            >
              <div>Save</div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="editMyProfileContainer space-x-1 sm:space-x-2"
          onClick={() => (isUserVerified ? setIsEditable(true) : null)}
        >
          <div className="text-xs sm:text-base sm:mr=4">EDIT</div>
          <img className="h-3 w-3 sm:h-5 sm:w-5" src={EditIProfilecon} alt="edit-icon" />
        </div>
      )}
    </div>
  );
};

export default MyProfileTab;
