import axios from "axios";

export const getServerURL = () => {
  const staging = "https://ecomm2-api.cloudxier.dev"
  const localhost = "http://localhost:3123"
  const production = ""
  // Get host from here
  let host = window.location.host;
  if (host) {
    host = host.toLowerCase();
  }
  let env
  switch (host) {
    case "ecomm2-stg.cloudxier.dev":
      env = staging
      break;
    case "":
      env = production
      break;
    default:
      env = localhost
  }
  return env
}

const Axios = axios.create({
  baseURL: getServerURL(),
  withCredentials: true
});

export default Axios;
