import React, { useState } from "react";
import { useSelector } from "react-redux";

// Styles
import "../styles/fullscreenView.css";
import "../styles/navbar.css";

// React Lottie
import Lottie from "lottie-react";

// Icons and Images
import CloseFullscreenBtn from "../assets/itemImagesAndIcons/CloseFullscreenBtn.png";
import CheckedSizeConvertionIcon from "../assets/itemImagesAndIcons/CheckedSizeConvertionIcon.png";
import UncheckedSizeConvertionIcon from "../assets/itemImagesAndIcons/UncheckedSizeConvertionIcon.png";
import FirstPopUpBannerImg from "../assets/landingPageImagesAndIcons/FirstPopUpBannerImg.png";
import SwingClubLogo from "../assets/navbarIcons/SwingClubLogo.png";
import SwingClubLabel from "../assets/navbarIcons/SwingClubLabel.png";
import SearchIcon from "../assets/navbarIcons/SearchIcon.png";
import ValidIcon from "../assets/shoopingCartImagesAndIcons/ValidIcon.png";
import NoInternetConnection from "../assets/landingPageImagesAndIcons/NoInternetIcon.json";
import LoadingEcommerce from "../assets/loading/LoadingEcommerce.json";

// Components
import Scroller from "./Scroller";
import { InputText } from "./Inputs";
import Login from "../views/AuthenticationPages/Login";
import Register from "../views/AuthenticationPages/Register";
import ForgotPassword from "../views/AuthenticationPages/ForgotPassword";
import ResetPassword from "../views/AuthenticationPages/ResetPassword";
import ChangePassword from "../views/AuthenticationPages/ChangePassword";
import { formatPrice } from "../helpers/format-price";

const FullscreenView = (props) => {
  const {
    setterOpenFullscreen,
    flagger,
    navigate,
    authenticationState,
    searchKey,
    setSearchKey,
    notification,
    searchValue,
    handleOnClickSearchItem,
    handleOnClickStartShoppingBtn,
    categories,
    setterClickCategory
  } = props;
  const isMobileSize = useSelector((state) => state.isMobileSize);
  const searchState = useSelector((state) => state.searchState);

  const [arrSizeConversions, setArrSizeConversions] = useState([
    {
      sizeTitle: "Small (S)",
      sizeEquivalentToSize: "AU 8",
      sizeConversions: [
        { country: "US", value: "4" },
        { country: "UK", value: "8" },
        { country: "DE", value: "34" },
        { country: "DK", value: "34" },
        { country: "IT", value: "40" },
        { country: "JP", value: "7" },
        { country: "KR", value: "55" },
        { country: "RU", value: "42" },
      ],
      checked: true,
      chestSize: "87-93",
      waistSize: "74-80",
      seatSize: "85-91",
      armLengthSize: "62",
    },
    {
      sizeTitle: "Medium (M)",
      sizeEquivalentToSize: "AU 10",
      sizeConversions: [
        { country: "US", value: "4" },
        { country: "UK", value: "8" },
        { country: "DE", value: "34" },
        { country: "DK", value: "34" },
        { country: "IT", value: "40" },
        { country: "JP", value: "7" },
        { country: "KR", value: "55" },
        { country: "RU", value: "42" },
      ],
      checked: false,
      chestSize: "87-93",
      waistSize: "74-80",
      seatSize: "85-91",
      armLengthSize: "62",
    },
    {
      sizeTitle: "Large (G)",
      sizeEquivalentToSize: "AU 12",
      sizeConversions: [
        { country: "US", value: "4" },
        { country: "UK", value: "8" },
        { country: "DE", value: "34" },
        { country: "DK", value: "34" },
        { country: "IT", value: "40" },
        { country: "JP", value: "7" },
        { country: "KR", value: "55" },
        { country: "RU", value: "42" },
      ],
      checked: false,
      chestSize: "87-93",
      waistSize: "74-80",
      seatSize: "85-91",
      armLengthSize: "62",
    },
    {
      sizeTitle: "Extra Large (XL)",
      sizeEquivalentToSize: "AU 14",
      sizeConversions: [
        { country: "US", value: "4" },
        { country: "UK", value: "8" },
        { country: "DE", value: "34" },
        { country: "DK", value: "34" },
        { country: "IT", value: "40" },
        { country: "JP", value: "7" },
        { country: "KR", value: "55" },
        { country: "RU", value: "42" },
      ],
      checked: false,
      chestSize: "87-93",
      waistSize: "74-80",
      seatSize: "85-91",
      armLengthSize: "62",
    },
  ]);

  const handleClickCheckboxSize = (label) => {
    let finalArrSizeConversions = arrSizeConversions;
    finalArrSizeConversions = finalArrSizeConversions.map((sizeConversion) => {
      const { checked, sizeTitle } = sizeConversion;
      if (sizeTitle === label) {
        sizeConversion.checked = !checked;
      } else {
        sizeConversion.checked = false;
      }
      return sizeConversion;
    });
    setArrSizeConversions(finalArrSizeConversions);
  };

  if (flagger === "item details") {
    return (
      <div className="fullscreenContainer" tabIndex={0}>
        <div className="contentContainer">
          <div className="contentFullScreen">
            <div
              className="closeBtn"
              onClick={() => setterOpenFullscreen(false)}
            >
              <img
                src={CloseFullscreenBtn}
                alt="close-fullscreen-btn"
                width={isMobileSize ? 18 : 28}
                height={isMobileSize ? 18 : 28}
              />
            </div>
            <div className="fullScreenTitle">Size guide</div>
            {!isMobileSize ? (
              <div className="fullScreenMeasurementContainer">
                <div className="measurementLabel">Product measurements</div>
                <div className="measurementSwitchContainer">
                  <div className="measurementSwitchLabel">cm</div>
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                  <div className="measurementSwitchLabel">in</div>
                </div>
              </div>
            ) : null}
            {!isMobileSize ? (
              <div className="tableSizeContainer">
                <div className="tableSizeThead">
                  <div>Size</div>
                  <div>Chest</div>
                  <div>Waist</div>
                  <div>Seat</div>
                  <div>Arm length</div>
                </div>
                <div className="tableSizeTr">
                  {arrSizeConversions &&
                    arrSizeConversions &&
                    Array.isArray(arrSizeConversions) &&
                    arrSizeConversions.length > 0 &&
                    arrSizeConversions.map((sizeConversion, index) => {
                      const {
                        sizeTitle,
                        checked,
                        chestSize,
                        waistSize,
                        seatSize,
                        armLengthSize,
                      } = sizeConversion;
                      return (
                        <div key={index} className="trCenterContainer">
                          <div className="tdSizeTitleContainer">
                            <img
                              src={
                                checked
                                  ? CheckedSizeConvertionIcon
                                  : UncheckedSizeConvertionIcon
                              }
                              width={16}
                              height={16}
                              alt="checkbox-icon"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClickCheckboxSize(sizeTitle)}
                            />
                            <div className="sizeTitle">{sizeTitle}</div>
                          </div>
                          <div className="tdSizeValue">{chestSize}</div>
                          <div className="tdSizeValue">{waistSize}</div>
                          <div className="tdSizeValue">{seatSize}</div>
                          <div className="tdSizeValue">{armLengthSize}</div>
                        </div>
                      );
                    })}
                </div>
              </div>
            ) : null}
            <div
              className="tableMeasurementDescription"
              style={{ marginTop: "3em", marginBottom: "2em" }}
            >
              <div>
                Our size and fit team measure all items individually to help you
                find the perfect fit.
              </div>
              <div
                style={{
                  marginTop: "1em",
                  width: isMobileSize ? "100%" : "67.5%",
                }}
              >
                It may be helpful to compare these product measurements to a
                similar garment you already own. To do this, place the garment
                on a flat surface and take all measurements from the outside
              </div>
            </div>
            <div className="dividerContent"></div>
            <div className="sizeConversionsContainer">
              <div className="measurementLabel">Size conversions</div>
              <div
                className="tableMeasurementDescription"
                style={{ marginTop: "0.75em" }}
              >
                <div>Use the below conversion chart to find your size</div>
              </div>
              <div className="sizeConversionsContainer">
                {arrSizeConversions &&
                  arrSizeConversions &&
                  Array.isArray(arrSizeConversions) &&
                  arrSizeConversions.length > 0 ? (
                  <Scroller
                    contents={arrSizeConversions}
                    isMobileSize={isMobileSize}
                  />
                ) : null}
              </div>
            </div>
            <div className="contactUsContainer">
              <div className="measurementLabel">Contact us</div>
              <div
                className="tableMeasurementDescription"
                style={{ marginTop: "0.75em" }}
              >
                <div>
                  Still not sure what size to purchase? Email us at
                  swingclub@mail.com or call us at +61-9888 182 93
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (flagger === "landing page") {
    return (
      <div className="fullscreenContainer" tabIndex={0} style={{ alignItems: "center" }}>
        <div className="landingPageContentContainer">
          <img
            src={FirstPopUpBannerImg}
            alt="first_popup_banner"
            className="firstPopUpBannerImg"
          />
          <div className="beFirstToKnowContainer">
            <div className="contentContainerLandingPagePopUpBanner">
              <div
                className="closeBtn"
                onClick={() => setterOpenFullscreen(false)}
              >
                <img src={CloseFullscreenBtn} alt="close-fullscreen-btn" />
              </div>
              <div className="beFirstToKnowMainContainer">
                <div className="logoSectionFullScreen">
                  <img
                    src={SwingClubLogo}
                    alt="swing-club-logo"
                    className="swingClubLogoFullScreen"
                  ></img>
                  <img
                    src={SwingClubLabel}
                    alt="swing-club-label"
                    className="swingClubLabelFullScreen"
                  ></img>
                </div>
                <div className="beFirstWordStyle">
                  Be the first to know our updates
                </div>
                <div className="golfWithStyleWordStyle">
                  Golf with style with SwingClub. Enjoy 20% off for your first
                  order.
                </div>
                <div className="popUpBannerInputContainer">
                  <InputText
                    placeholder={"Enter your email here"}
                    width={isMobileSize ? "25vw" : "72%"}
                    height={isMobileSize ? "3vh" : "4.75vh"}
                    name="emailFullScreenLandingPage"
                    border={"1px solid #000000"}
                    borderRadius={isMobileSize ? "3px" : "5px"}
                    fontSize={isMobileSize ? "2vw" : "1vw"}
                  />
                  <div className="notifyMeBtn">
                    <div>Notify me</div>
                  </div>
                </div>
              </div>
              <div className="termsAndConditionContainer">
                <div>Terms and Conditions</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (flagger === "authentication-form") {
    return (
      <div className="fullscreenContainer" tabIndex={0}>
        {authenticationState && authenticationState.login ? (
          <Login
            setterOpenFullscreen={setterOpenFullscreen}
            authenticationState={authenticationState}
            CloseFullscreenBtn={CloseFullscreenBtn}
            notification={notification}
            isMobileSize={isMobileSize}
          />
        ) : authenticationState && authenticationState.register ? (
          <Register
            setterOpenFullscreen={setterOpenFullscreen}
            authenticationState={authenticationState}
            CloseFullscreenBtn={CloseFullscreenBtn}
            notification={notification}
            isMobileSize={isMobileSize}
          />
        ) : authenticationState && authenticationState.forgotPassword ? (
          <ForgotPassword
            setterOpenFullscreen={setterOpenFullscreen}
            authenticationState={authenticationState}
            CloseFullscreenBtn={CloseFullscreenBtn}
            notification={notification}
            isMobileSize={isMobileSize}
          />
        ) : authenticationState && authenticationState.resetPassword ? (
          <ResetPassword
            setterOpenFullscreen={setterOpenFullscreen}
            authenticationState={authenticationState}
            CloseFullscreenBtn={CloseFullscreenBtn}
            notification={notification}
            isMobileSize={isMobileSize}
          />
        ) : authenticationState && authenticationState.changePassword ? (
          <ChangePassword 
            setterOpenFullscreen={setterOpenFullscreen}
            authenticationState={authenticationState}
            CloseFullscreenBtn={CloseFullscreenBtn}
            notification={notification}
            isMobileSize={isMobileSize}
          />
        ) : null}
      </div>
    );
  } else if (flagger === "navbar-search-input") {
    let categories = searchValue && searchValue.categories;
    let products = searchValue && searchValue.products;
    let brands = searchValue && searchValue.brands;
    let sub_categories = searchValue && searchValue.sub_categories;
    let isEmpty = true;
    if (categories && products && brands && sub_categories) {
      if (
        sub_categories.length > 0 ||
        brands.length > 0 ||
        products.length > 0 ||
        categories.length > 0
      ) {
        isEmpty = false;
      }
    }
    return (
      <div className="fullscreenNavbarSearchInput" tabIndex={0}>
        <div className="navbarSearchInputMainContainer">
          <div className="navbarSearchInputContainer">
            <div className="searchOrderContainer">
              <img
                src={SearchIcon}
                alt="search-icon"
                className="navbarSearchIcon"
              />
              <InputText
                borderRadius={"10px"}
                width={"100vw"}
                height={isMobileSize ? "3.5vh" : "5.5vh"}
                backgroundColor={"#F9F9F9"}
                placeholder={"Search SWING-CLUB ..."}
                border={"none"}
                paddingLeft={isMobileSize ? "8vw" : "4vw"}
                fontSize={isMobileSize ? "2.6vw" : "1.2vw"}
                setter={setSearchKey}
                value={searchKey}
              />
              <div className="navbarSearchInputClearAndCloseBtn">
                <div
                  className="clearBtnStyle"
                  onClick={() => setSearchKey("")}
                  style={{ color: searchKey ? "#000000" : "#AAAAAA" }}
                >
                  Clear
                </div>
                <div
                  className="closeBtn"
                  onClick={() => setterOpenFullscreen(false)}
                >
                  <img src={CloseFullscreenBtn} alt="close-fullscreen-btn" />
                </div>
              </div>
            </div>
          </div>
          {!isEmpty ? (
            <div className="navbarSearchValueMainContainer">
              <div className="navbarSearchValueContainer">
                <div className="navbarSearchValueProductMainContainer">
                  {categories &&
                    Array.isArray(categories) &&
                    categories.length > 0 && (
                      <>
                        <div className="navbarSearchValueLabel">Categories</div>
                        <div
                          className="navbarSearchValueContentContainer"
                          style={{ marginLeft: "1vw" }}
                        >
                          {categories.map((category, index) => {
                            const { id, name, description, image_url } =
                              category || {};
                            return (
                              <div
                                key={id}
                                className="eachSearchValueContainer"
                                onClick={() =>
                                  handleOnClickSearchItem(category, "category")
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div className="eachSearchValueContentContainer">
                                  {image_url ? (
                                    <img
                                      src={image_url}
                                      alt={`Each-item-${index + 1}`}
                                    />
                                  ) : (
                                    <div className="w-full h-full border flex items-center justify-center">
                                      {name}
                                    </div>
                                  )}
                                </div>
                                <div className="eachSearchValueName">
                                  <div>{name}</div>
                                </div>
                                <div className="eachSearchValueDesc">
                                  {description}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  {products &&
                    Array.isArray(products) &&
                    products.length > 0 && (
                      <>
                        <div
                          className="navbarSearchValueLabel"
                          style={
                            !isMobileSize
                              ? { marginTop: "2vh", marginBottom: "2vh" }
                              : {}
                          }
                        >
                          Products
                        </div>
                        <div className="navbarSearchValueContentContainer">
                          {products.map((product, index) => {
                            const { id, name, images, brand, price } =
                              product || {};
                            let product_img_url;
                            if (
                              images &&
                              Array.isArray(images) &&
                              images.length > 0
                            ) {
                              product_img_url =
                                images[0] && images[0].image_url;
                            }
                            return (
                              <div
                                key={id}
                                className="eachSearchValueContainer"
                                onClick={() => {
                                  setterOpenFullscreen(false);
                                  navigate(`/items/${id}`);
                                }}
                                style={
                                  !isMobileSize
                                    ? {
                                      margin: "0vh 2vw 0vh 2vw",
                                      cursor: "pointer",
                                    }
                                    : {}
                                }
                              >
                                <div className="eachSearchValueContentContainer">
                                  <img
                                    src={product_img_url}
                                    alt={`Each-item-${index + 1}`}
                                  />
                                </div>
                                <div className="eachSearchValueDesc">
                                  {brand?.name}
                                </div>
                                <div className="eachSearchValueName">
                                  {name}
                                </div>
                                <div className="eachSearchValueDesc">
                                  {searchState?.currencyVal?.code} {formatPrice(price)}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          ) : searchKey && isEmpty ? (
            <div className="emptyNavbarSearchValueMainContainer">
              <div className="emptyNavbarSearchValueContainer">
                No products found
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  } else if (flagger === "navbar-mobile-menu") {
    return (
      <div className="fullScreenNavbarMobileMenus" tabIndex={0}>
        <div className="navbarMobileMenuMainContainer">
          <div className="navbarMobileMenuContainer">
            <div
              className="closeBtn"
              onClick={() => setterOpenFullscreen("close-btn")}
            >
              <img src={CloseFullscreenBtn} alt="close-fullscreen-btn" />
            </div>
            <div className="navbarMobileMenuSectionContainer">
              {categories &&
                Array.isArray(categories) &&
                categories.length > 0 &&
                categories.map((category, index) => {
                  const { name } = category || {};
                  return (
                    <div
                      key={index}
                      className="eachNavbarMobileMenu"
                      onClick={() => {
                          setterOpenFullscreen(false)
                          setterClickCategory(category);
                        }
                      }
                    >
                      <div>
                        {name}
                      </div>
                    </div>
                  );
              })}
              <div
                className="eachNavbarMobileMenu"
                onClick={() => {
                  setterOpenFullscreen(false);
                  navigate("/cart");
                }}
              >
                Cart
              </div>
              <div
                className="eachNavbarMobileMenu"
                onClick={() => setterOpenFullscreen("account")}
              >
                Account
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (flagger === "no-connection-modal") {
    return (
      <div className="fullscreenContainer" tabIndex={0}>
        <div
          className="landingPageContentContainer"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Lottie
            animationData={NoInternetConnection}
            loop={true}
            style={{
              height: 275,
            }}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <h1
              style={{
                color: "black",
              }}
            >
              Whoops!
            </h1>
            <h3
              style={{
                color: "black",
                width: isMobileSize ? "60vw" : "25vw",
              }}
            >
              No Internet connection found. Check your connection or try again.
            </h3>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              style={{
                background: "#19b0df",
                borderRadius: "10px",
                cursor: "pointer",
              }}
              onClick={() => window.location.reload()}
            >
              <div
                style={{
                  fontSize: isMobileSize ? "3vw" : "0.95vw",
                  color: "#FFFFFF",
                  margin: isMobileSize
                    ? "1.25vh 2vw 1.25vh 2vw"
                    : "1.25vh 1vw 1.25vh 1vw",
                }}
              >
                Try again
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (flagger === "loading-animation") {
    return (
      <div className="loadingAnimationMainContainer" tabIndex={0}>
        <div
          className="loadingAnimationContainer"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Lottie
            animationData={LoadingEcommerce}
            loop={true}
            style={{ height: 275 }}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <h1 style={{ color: "black", fontWeight: "600" }}>Loading...</h1>
          </div>
        </div>
      </div>
    );
  } else if (flagger === "notification-success-verify-user") {
    return (
      <div className="fullscreenContainer" tabIndex={0}>
        <div
          className="landingPageContentContainer"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            borderRadius: "30px",
            width: isMobileSize ? "70vw" : "35vw",
            height: isMobileSize ? "50vh" : "55vh",
          }}
        >
          <div
            className="shoppingCartSuccessPaymentMainContainer"
            style={{ marginTop: "4vh", marginBottom: "2vh" }}
          >
            <div className="successPaymentIconContainer">
              <img src={ValidIcon} alt="valid-icon" />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              marginBottom: isMobileSize ? "4vh" : "5vh",
            }}
          >
            <h3
              style={{
                color: "black",
                width: isMobileSize ? "57.5vw" : "20vw",
              }}
            >
              Your account successfully registered.
            </h3>
            <h3
              style={{
                color: "black",
                width: isMobileSize ? "57.5vw" : "20vw",
                paddingTop: "1vh",
              }}
            >
              Enjoy shopping
            </h3>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              style={{
                background: "#19b0df",
                borderRadius: "10px",
                cursor: "pointer",
              }}
              onClick={() => handleOnClickStartShoppingBtn()}
            >
              <div
                style={{
                  fontSize: isMobileSize ? "3vw" : "0.95vw",
                  color: "#FFFFFF",
                  margin: isMobileSize
                    ? "1.25vh 2vw 1.25vh 2vw"
                    : "1.25vh 1vw 1.25vh 1vw",
                }}
              >
                Start shopping
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (flagger === "notification-success-request-reset-password") {
    return (
      <div className="fullscreenContainer" tabIndex={0}>
        <div
          className="landingPageContentContainer"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            borderRadius: "25px",
            width: isMobileSize ? "70vw" : "25vw",
            height: isMobileSize ? "35vh" : "45vh",
          }}
        >
          <div
            className="shoppingCartSuccessPaymentMainContainer"
            style={{ marginTop: "0", marginBottom: "4vh" }}
          >
            <div className="successPaymentIconContainer">
              <img src={ValidIcon} alt="valid-icon" />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <h3
              style={{
                color: "black",
                width: isMobileSize ? "57.5vw" : "20vw",
              }}
            >
              Reset password link sent to your email message
            </h3>
          </div>
        </div>
      </div>
    );
  } else if (flagger === "notification-success-reset-password") {
    return (
      <div className="fullscreenContainer" tabIndex={0}>
        <div
          className="landingPageContentContainer"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            borderRadius: "25px",
            width: isMobileSize ? "70vw" : "25vw",
            height: isMobileSize ? "35vh" : "45vh",
          }}
        >
          <div
            className="shoppingCartSuccessPaymentMainContainer"
            style={{ marginTop: "0", marginBottom: "4vh" }}
          >
            <div className="successPaymentIconContainer">
              <img src={ValidIcon} alt="valid-icon" />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <h3
              style={{
                color: "black",
                width: isMobileSize ? "57.5vw" : "20vw",
              }}
            >
              Your password has been reset
            </h3>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
export default FullscreenView;
